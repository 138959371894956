import { defineStore } from 'pinia'
import { useLocalStorage } from '@vueuse/core'
import { useNuxtApp } from '#app'
import useSupabaseAuth from '~/composables/useSupabaseAuth'
import type { IUser, OAuthProvider } from '~/types'

/**
 * Store for managing user authentication and preferences.
 * Handles user data, authentication flows, and language preferences.
 */
export const useAuthStore = defineStore('auth', {
  /**
   * State: Contains properties related to user, route, and preferences.
   * - `user`: User's information stored in local storage.
   * - `route`: Current route information.
   * - `preferences`: User's UI and database language preferences stored in local storage.
   */
  state: () => ({
    user: useLocalStorage<IUser>('supabase_user', { uid: '', email: '', name: '' }),
    route: useRoute(),
    preferences: useLocalStorage('preferences', {
      uiLanguage: 'en'
    }, { deep: true, mergeDefaults: true })
  }),
  actions: {
    /**
     * Sends a magic link email for authentication.
     * @param email - The email address to send the magic link to.
     * @param locale - The locale setting for the email.
     * @returns A promise indicating the completion of the email request.
     */
    async sendMail (email: string, locale:string) {
      const { requestMagicLinkMail } = useSupabaseAuth()
      this.user.email = email
      return await requestMagicLinkMail(email, locale)
    },
    /**
     * Signs in the user using OAuth.
     * @param provider {OAuthProvider} - The OAuth provider to use for authentication.
     * @returns A promise indicating the completion of the OAuth request.
     */
    async oAuthSignIn (provider: OAuthProvider) {
      const { signInWithOAuth } = useSupabaseAuth()

      await signInWithOAuth(provider)
    },
    /**
     * Completes the sign-in process after redirecting from OAuth or magic link.
     */
    async completeSignIn () {
      const { user, isInOAuthFlow, completeSignIn } = useSupabaseAuth()

      if (!isInOAuthFlow() && !this.user.email) {
        let email = null
        while (!email) {
          email = window.prompt('Please enter your email address')
        }

        this.user.email = email
      }

      await completeSignIn(this.user.email)
      this.user = { ...user.value }
    },
    async completeOtpSignIn (email: string, otp: string) {
      const { user, verifyOtp } = useSupabaseAuth()
      await verifyOtp(email, otp)
      this.user = { ...user.value }
    },
    /**
     * Signs out the user.
     */
    async logout () {
      const { $auth } = useNuxtApp()
      this.user = { email: '', name: '', uid: '' }
      await $auth.signOut({ scope: 'local' })
    },
    async updateUser (userData: { name: string, email: string }) {
      const { $api } = useNuxtApp()
      const { ...user } = await $api.laoshi.updateUser(this.user.uid, userData)
      this.user.name = user.username
      this.user.email = user.email
    },
    updateLanguage (language: string) {
      this.preferences.uiLanguage = language
      localStorage.setItem('preferences', JSON.stringify(this.preferences))
      const isValidLanguage = ['en', 'ru', 'ua', 'ja', 'th', 'vi', 'zh', 'ko', 'id'].includes(language)
      const { $api } = useNuxtApp()
      $api.laoshi.changeLanguage(isValidLanguage ? language : 'en')
    }
  },
  getters: {
    /**
     * Determines if the user is in the login flow.
     * @returns `true` if the user is in the process of logging in, `false` otherwise.
     */
    isInLoginFlow: (state) => {
      return Boolean(state.user.email) || Boolean(state.route.hash) || Boolean(Object.keys(state.route.query).length)
    },
    /**
     * Determines if the user is a guest.
     * @param state
     */
    isGuest: (state) => {
      return !state.user.uid
    }
  }
})

import { AuthError, type Session, type User } from '@supabase/supabase-js'
import clamp from 'lodash/clamp'
import { AxiosError } from 'axios'
import { parseParametersFromURL } from '@supabase/auth-js/src/lib/helpers'
import type { IUser, OAuthProvider } from '~/types'

const generateFirebaseUid = () => {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  let uid = ''
  for (let i = 0; i < 28; i += 1) {
    uid += chars.charAt(Math.floor(Math.random() * chars.length))
  }
  return uid
}

/**
 * Authentication-related function using Supabase.
 * Provides methods for handling user sign-in, sign-up, and managing authentication state.
 */
export default function () {
  const { $auth, $api } = useNuxtApp()

  const user = useState<IUser>('supabase_user', () => ({
    email: '',
    name: '',
    uid: ''
  }))

  /**
   * Requests a magic link email for user authentication.
   * @param email - The user's email address.
   * @param locale - The locale setting for the email.
   * @returns A promise resolving to `true` if the email is sent successfully.
   */
  const requestMagicLinkMail = async (email: string, locale:string): Promise<boolean> => {
    try {
      await $api.auth.login(email, locale)
      return true
    } catch (e) {
      return false
    }
  }

  /**
   * Initiates OAuth sign-in with a specified provider.
   * @param provider - The OAuth provider ('apple' or 'google').
   */
  const signInWithOAuth = async (provider: OAuthProvider) => {
    await $auth.signInWithOAuth({
      provider,
      options: { redirectTo: `${window.location.origin}/complete-sign-in/` }
    })
  }

  /**
   * Parses URL parameters and handles authentication errors.
   * @throws {AuthError} Throws an error if there are issues with authentication parameters.
   * @returns Parsed URL parameters.
   */
  const parseUrlParams = () => {
    const params = parseParametersFromURL(window.location.href)

    // e.g. #error=unauthorized_client&error_code=401&error_description=Email+link+is+invalid+or+has+expired
    //      ?error=server_error&error_description=Unable+to+exchange+external+code:<>
    if (params.error) {
      const errorCode = parseInt(params.error_code) || undefined
      throw new AuthError(params.error_description, errorCode)
    }

    return params
  }

  /**
   * Determines if the current flow is an OAuth authentication flow.
   * @throws {AuthError} Throws an error if there are issues with authentication parameters.
   * @returns `true` if in OAuth flow, otherwise `false`.
   */
  const isInOAuthFlow = () => {
    const params = parseUrlParams()
    return Boolean(params.provider_token)
  }

  /**
   * Fetches or creates user information in the application database based on Supabase user data.
   * @param supabaseUser - The user data from Supabase.
   */
  const fetchOrCreateUserInfo = async (supabaseUser: User) => {
    const { uid: supabaseUid, displayName } = supabaseUser.user_metadata

    let userInfo
    try {
      userInfo = await $api.laoshi.getUserByMail(supabaseUser.email)
    } catch (e) {
      if (e instanceof AxiosError && e?.response?.status === 404) {
        const newUid = generateFirebaseUid()
        const number = Math.floor(1000 + Math.random() * 9000)
        const newUsername = `User${clamp(number, 1000, 9999)}`

        userInfo = await $api.laoshi.createUser({
          uid: supabaseUid || newUid,
          email: supabaseUser.email,
          username: displayName || newUsername
        })
      } else {
        throw e
      }
    }

    if (!supabaseUid) {
      await $auth.updateUser({ data: { uid: userInfo.uid, displayName: userInfo.username } })
    }

    user.value = {
      email: userInfo.email,
      name: userInfo.username,
      uid: userInfo.uid
    }
  }

  const verifyOtp = async (email:string, token: string) => {
    const { data, error } = await $auth
      .verifyOtp({ type: 'email', email, token })
    await processErrors(data, error, email, {})
    await fetchOrCreateUserInfo(data.user)
  }

  /**
   * Completes the sign-in process, setting up the user session.
   * @param email - Optional email address for additional validation during sign-in.
   * @throws {AuthError} Throws an error if there are issues completing the sign-in process.
   */
  const completeSignIn = async (email?: string) => {
    const params = parseUrlParams()

    // e.g. magiclink: #access_token=<>&expires_at=1700000000&expires_in=3600&refresh_token=<>&token_type=bearer&type=magiclink
    //                 #access_token=<>&expires_at=1700000000&expires_in=3600&refresh_token=<>&token_type=bearer&type=signup
    //   google oauth: #access_token=<>&expires_at=1700000000&expires_in=3600&provider_token=<>&refresh_token=<>&token_type=bearer
    const { data, error } = await $auth.setSession({
      access_token: params.access_token,
      refresh_token: params.refresh_token
    })
    await processErrors(data, error, email, params)

    await fetchOrCreateUserInfo(data.user)
  }

  const processErrors = async (data: {user: User, session: Session}, error: AuthError, email: string, params: any) => {
    if (error) {
      throw error
    }
    if (!data.user || !data.session) {
      throw new AuthError('Login failed, please try again', 403)
    }
    if (!data.user.email) {
      await $auth.signOut({ scope: 'local' })
      throw new AuthError('Email not found', 403)
    }
    if (!params.provider_token && data.user.email.toLowerCase() !== email.toLowerCase()) {
      await $auth.signOut({ scope: 'local' })
      throw new AuthError("Provided email doesn't match with user email")
    }
  }
  return {
    user,
    completeSignIn,
    isInOAuthFlow,
    signInWithOAuth,
    requestMagicLinkMail,
    verifyOtp
  }
}
